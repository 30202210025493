import { environment } from './../../../../environments/environment';
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { StudentService } from '../../../student/services/student.service';

@Component({
  selector: 'app-upgrade-entity-popup',
  templateUrl: './upgrade-entity-popup.component.html',
  styleUrls: ['./upgrade-entity-popup.component.scss']
})
export class UpgradeEntityPopupComponent implements OnInit {

  public attempts:any = undefined
  public allowAttempts:any = 3;
  alertMsgFlag: boolean = false;
  constructor(public dialog: MatDialogRef<UpgradeEntityPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _router: Router, private _authService: AuthService,private studentService:StudentService){
  }

  ngOnInit() {
   
  }
  onConfirm(){
    //this._router.navigate(['/assessment']);
    if(this.data.submit) {
      this.dialog.close(true);
    }
    else {
      this.dialog.close(this.data.nextRoute)
    }
    
  }

  closeDialog(): void {
    this._router.navigateByUrl('/student/dashboard');

    this.dialog.close();
  }

  cancelDialog(): void {
    this.dialog.close();
  }

}
