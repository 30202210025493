import { LocalstorageService } from 'src/app/services/localstorage.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    userToken: string = "";
    constructor(private _authService: AuthService, private _localStorage: LocalstorageService, private spinner: NgxSpinnerService, private router: Router) {
        if (this.userToken == '') {
            if (this._localStorage.getCookie('token')) {
                this.userToken = this._localStorage.getCookie('token')
            }
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('line no. 29', request.url);
        if (request.url.indexOf('execute-api.ap-south-1.amazonaws') >= 0) {
            //check if token is stored already
            if (request.url.indexOf('/signup/verify') >= 0
                || request.url.indexOf('/notification/password') >= 0
                || request.url.indexOf('/countries-states-cities') >= 0
                || request.url.indexOf('/payments/others') >= 0
                || request.url.indexOf('entity?name') >= 0
            ) {
                return next.handle(request);
            }
            if (this.userToken != '') {
                request = request.clone({
                    setHeaders: {
                        "Content-Type": 'application/json',
                        "Authorization": this.userToken
                    }
                });
                return next.handle(request).pipe(
                    catchError((errorData) => {
                        console.log("line no. 41 request status", errorData.status);
                        console.log("line no. 42 request status", request.url);
                        if (errorData.status === 401 || errorData.status === 403) {
                            // Refresh token logic on 03042023
                            this._authService.handleRefreshToken(() => {
                                // write logic to get new token from web storage and send it to the auth header 03042023
                                if (this._localStorage.getCookie('token')) {
                                    this.userToken = this._localStorage.getCookie('token');
                                    request = request.clone({
                                        setHeaders: {
                                            "Content-Type": 'application/json',
                                            "Authorization": this.userToken
                                        }
                                    });
                                }
                                // this.reloadCurrentRoute();
                                window.location.reload();
                                this.spinner.hide()
                            });

                        }
                        return throwError(errorData);
                    })
                );
            }
            else {
                if (this._localStorage.getCookie('token')) {
                    this.userToken = this._localStorage.getCookie('token')
                    request = request.clone({
                        setHeaders: {
                            "Content-Type": 'application/json',
                            "Authorization": this.userToken
                        }
                    });
                    //console.log(this.userToken,request.url)
                    return next.handle(request).pipe(
                        catchError((errorData) => {
                            console.log("line no. 78 request status", errorData.status);
                            if (errorData.status === 401 || errorData.status === 403) {
                                // Refresh token logic on 03042023
                                this._authService.handleRefreshToken(() => {
                                    // write logic to get new token from web storage and send it to the auth header 03042023
                                    if (this._localStorage.getCookie('token')) {
                                        this.userToken = this._localStorage.getCookie('token');
                                        request = request.clone({
                                            setHeaders: {
                                                "Content-Type": 'application/json',
                                                "Authorization": this.userToken
                                            }
                                        });
                                    }
                                    // this.reloadCurrentRoute();
                                    window.location.reload();
                                    this.spinner.hide();
                                });

                            }
                            return throwError(errorData);
                        })
                    );
                }
                //fetch token if not available
                this._authService.getCurrentUserObj().then(data => {
                    this.userToken = data.signInUserSession.idToken.jwtToken;
                    request = request.clone({
                        setHeaders: {
                            "Content-Type": 'application/json',
                            "Authorization": this.userToken
                        }
                    });
                    return next.handle(request).pipe(
                        catchError((errorData) => {
                            console.log("line no. 112 request status", errorData.status);
                            if (errorData.status === 401 || errorData.status === 403) {
                                // Refresh token logic on 03042023
                                this._authService.handleRefreshToken(() => {
                                    // write logic to get new token from web storage and send it to the auth header 03042023
                                    if (this._localStorage.getCookie('token')) {
                                        this.userToken = this._localStorage.getCookie('token');
                                        request = request.clone({
                                            setHeaders: {
                                                "Content-Type": 'application/json',
                                                "Authorization": this.userToken
                                            }
                                        });
                                    }
                                    // this.reloadCurrentRoute();
                                    window.location.reload();
                                    this.spinner.hide();
                                });

                            }
                            return throwError(errorData);
                        })
                    );

                }, error => console.log(error)).catch(error => { console.log(error); return next.handle(request); })
            }
        }
        else {
            return next.handle(request);
        }


    }

    // reload current route when the token has been expired 04042023
    reloadCurrentRoute() {

        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);

    }
}
