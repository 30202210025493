import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { MAT_SINGLE_DATE_SELECTION_MODEL_PROVIDER } from '@angular/material/datepicker';
import { StudentService } from 'src/app/student/services/student.service';
import { FinalPopupComponent } from 'src/app/student/popups/final-popup/final-popup.component';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': '',
  })
};

@Injectable({
  providedIn: 'root'
})


export class PsychometricService {
  SharingAnimationData = new Subject();
  startTimerData = new Subject();
  startTimerDataPersonality = new Subject();
  showOverlayInterest: boolean = false;
  showInfoFlag: boolean = false;
  assessmentSectionsData: any = undefined;
  subSectionsData: any = []
  private apiUrl: string;
  shareTabSectionData: any;
  showOverlayPersonality = false;
  SharingAnimationDataPersonality = new Subject();
  aptitudeTestSectionData = new Subject();
  aptiSelectedSecIndex: any = 0;
  aptiForAccuracy: any = 0;
  // added on 17012023
  cInstructionArr: any = [];

  constructor(private http: HttpClient, private _studentService: StudentService, private dialog: MatDialog) {
    const BASE_API_URL = environment.ApiConfig.psychometric;
    this.apiUrl = `${BASE_API_URL}`;
    // added for common instruction data on 17012023
    this.cInstructionArr = [{ "title": "There are a total of four psychometric assessments." },
    { "title": "Some of the assessments are time-based." },
    { "title": "The timer may or may not be visible to you on the screen depending on the assessment requirement." },
    { "title": "Read the instructions of each assessment thoroughly and watch the demonstration for your reference." },
    { "title": "Please note that you cannot exit/navigate in between the test unless you have finished it." },
    { "title": "Please focus on finishing the assessment without rushing through them." },
    { "title": "Be relaxed and enjoy the process." }];

  }

  //GET
  getInterestQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions`);
  }

  getSubSections(sectionId) {
    return this.http.get(`${this.apiUrl}/student/assesment/sub-sections?section_id=${sectionId}`);
  }

  getAptitudeQuestions(subSection) {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude?sub_section_id=${subSection}`);
  }

  //Individual aptitude test questions need to be fetched
  getNumericalQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/numerical`);
  }

  getAccuracyQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/accuracy`);
  }

  getPerceptionQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/closure`);
  }

  getVerbalQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/verbal`);
  }

  checkAssessmentReward() {
    let userSub = sessionStorage.getItem('userSub');
    this._studentService.getStudentDetails('basic').subscribe((basicData: any) => {
      if (!basicData.error) {
        basicData = basicData[0]
        let totalPoints = basicData['total_earn_points'] ? basicData['total_earn_points'] : '200';
        let assessmentDetails = basicData.assessment_details ? JSON.parse(basicData.assessment_details) : {}
        if (assessmentDetails['Interest'] && assessmentDetails['Personality'] && assessmentDetails['Multiple Intelligences']) {
          let aptitudeSection = assessmentDetails['Aptitude'];
          let completed = 0;
          let total = 0
          for (let key in aptitudeSection) {
            if (aptitudeSection.hasOwnProperty(key)) {
              if (aptitudeSection[key]) {
                completed++;
              }
              total++
            }
          }
          if (total == completed) {
            let postData: any = {}
            this._studentService.getRewards().subscribe(
              data => {
                let rewardData: any = data;
                let obj = rewardData.find(o => o.activity_tag === 'ASSESSMENT');
                postData.activity_tag = obj.activity_tag;
                postData.rewardId = obj.id;
                postData.uuid = userSub
                this._studentService.updateRewardPoints(postData).subscribe((response: any) => {
                  if (response.success != false) {
                    const dialogRef = this.dialog.open(FinalPopupComponent, {
                      width: '500px',
                      height: '400px',
                      panelClass: 'custom-final-popup',
                      data: { msg: 'Assessment Complete', points: obj.points, left: '40%' }
                    });
                    dialogRef.afterClosed().subscribe(() => {
                      this._studentService.getStudentDetails('basic').subscribe((userData) => {
                        this._studentService.totalPoints = userData[0].total_earn_points;
                      })
                    })

                  }
                })
              },
              error => { }
            );
          }
        }
      }
    })
  }


  getMechanicalQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/mechanical`);
  }

  getReasoningQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/reasoning`);
  }

  getSpatialQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/aptitude/spatial`);
  }

  getAssessmentContent() {
    return this.http.get(`${this.apiUrl}/student/assesment/content`);
  }

  getAssessmentContentTab(uuid) {
    return this.http.get(`${this.apiUrl}/student/assesment/sections?uuid=${uuid}`);
  }


  getAssessmentSectionContent(id) {
    return this.http.get(`${this.apiUrl}/student/assesment/sections-content/${id}`);
  }

  getTopCarrerForIntrestReport(uuid) {
    return this.http.get(`${this.apiUrl}/student/assesment/result/interest/top-careers?sub=${uuid}`);
  }
  getIntrestReportData(uuid) {
    return this.http.get(`${this.apiUrl}/student/assesment/result/interest/?sub=${uuid}`);
  }

  getPersonalityReportData(uuid) {
    return this.http.get(`${this.apiUrl}/student/assesment/result/personality?sub=${uuid}`);
  }

  getMiReportData(uuid) {
    return this.http.get(`${this.apiUrl}/student/assesment/result/mi?sub=${uuid}`);
  }

  getAptitudeReportData(uuid) {
    return this.http.get(`${this.apiUrl}/student/assesment/result/aptitude?sub=${uuid}`);
  }


  getIntrestChartData(id) {
    return this.http.get(`${this.apiUrl}/student/assesment/graph/interest/${id}`);
  }

  getPersonalityChartData(id) {
    return this.http.get(`${this.apiUrl}/student/assesment/graph/personality/${id}`);
  }

  getMiChartData(id) {
    return this.http.get(`${this.apiUrl}/student/assesment/graph/mi/${id}`);
  }

  getAptitudeChartData(id) {
    return this.http.get(`${this.apiUrl}/student/assesment/graph/aptitude/${id}`);
  }
  //GET
  getQuestions(sectionId) {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/assesment/questions?section_id=${sectionId}&id=${userSub}`);
    //return this.http.get(`${this.apiUrl}/student/assesment/questions/personality`);

  }

  //GET
  getMIQuestions() {
    return this.http.get(`${this.apiUrl}/student/assesment/questions`);
  }

  //GET
  getPersonalityQuestions(sectionId) {
    return this.http.get(`${this.apiUrl}/student/assesment/questions/personality`);
  }

  //PATCH
  updateQuestions(data) {
    return this.http.patch(`${this.apiUrl}/`, data);
  }

  //POST
  addQuestions(data) {
    return this.http.post(`${this.apiUrl}/student/assesment/responses/interest`, data);
  }

  //POST
  addQuestionsForMi(data) {
    return this.http.post(`${this.apiUrl}/student/assesment/responses/mi`, data);
  }

  //POST
  addQuestionsForPersonality(data) {
    return this.http.post(`${this.apiUrl}/student/assesment/responses/personality`, data);
  }

  //POST FOR APTITUDE
  addQuestionsForAptitude(data) {
    return this.http.post(`${this.apiUrl}/student/assesment/responses/aptitude`, data);
  }

  addQuestionsForAptitudeSpacial(data) {
    return this.http.post(`${this.apiUrl}/student/assesment/responses/aptitude/spatial`, data);
  }
  //DELETE
  deleteQuestion(data) {
    return this.http.delete(`${this.apiUrl}/`, data);
  }

  getSectionDetails(sectionId) {
    return this.http.get(`${this.apiUrl}/student/assesment/sections/${sectionId}`);
  }

  //POST Intrest Section
  addDataForIntrest(data) {
    return this.http.post(`${this.apiUrl}/student/assesment/responses`, data);
  }

  //GET Instructions
  getInstruction(sectionId) {
    return this.http.get(`${this.apiUrl}/student/assesment/instructions?section_id=${sectionId}`);
  }

  getAptitudeInstructions(subSectionId) {
    return this.http.get(`${this.apiUrl}/student/assesment/instructions?sub_section_id=${subSectionId}`);
  }
}
